.navbar {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;

  .wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    
    justify-content: center;

    .logo {
      display: flex;
      align-items: center;
      //margin-left: 30px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 14px;
        }
      }

      &:hover {
        color: #7451f8;
      }
    }

    .center {
      display: flex;
      align-items: center;
      .graficosSide{
        padding: 2px 4px; 
        background-color: #FFF;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;

        .text-links{
          margin-left: 3px;
          font-size: 15px;
          color: rgb(78, 78, 78);

        }
        i {
          font-size: 20px;
          color: #6B4380;
          margin-left: 5px;

        }
        .selectSize{
          border-radius: none;
          border: none;
          background-color:transparent;
          font-size: 15px;
          color: rgb(78, 78, 78);
          cursor: pointer;
        }

      }
    }
    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
        &:hover {
          color: #7451f8;
        }
        .noChat {
          display: flex;
          position: absolute;
          top: 24px;
        }
        .chatIcon {
          display: flex;
        }
        .icon {
          font-size: 25px;
          color:  rgb(78, 78, 78);
        }

        .count {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
        .avatar {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          box-shadow: 0px 0px 5px 1px rgba(125, 125, 125, 0.3);
        }
        .userName {
          font-size: 16px;
          font-weight: 600;
          padding-left: 5px;
        }

        .icones-zoom{
          display: flex;
          .icon-add{
            color: #FADF59;
            cursor: pointer;
          }
          .icon-remove{
            cursor: pointer;
            color: #705383;
          }
          .icon-input{
            border: 1px solid #BCB0B0;
            margin: auto 0px;
            border-radius: 3px;
            display: flex;
            width: 35px;
            height: 15px;
          }
      }
      }
    }
  }
}
