.saldo{
    display: flex;

    .homeCont{
        flex: 6;
        
        .content{
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 230px); 
            align-items: center;
            justify-content: space-evenly;

            width: 100%;

            .info-avisos{
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 300px;
            }

            .loader{
                top: 40%;
                left: 0;
                width: 100%;
                height: 70px;
                justify-content: center;
                align-items: center;
                display: flex;
                position: fixed;
                background: #ffffffc0;
            }
            .titulo{
                display: flex ;
                width: auto;
            }

            .input-sado{
                display: flex ;
                width: auto;
                flex-direction: column;
            }
            .teclado-saldo{
                display: flex ;
                width: auto;
            }
            .botao-saldo{
                display: flex ;
                width: auto;

            }
            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
                flex: 1;
            }
        }
    }
}