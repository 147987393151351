@import url('https://fonts.googleapis.com/css2?family=Material+Icons&display=swap');
* {
  transition: background ease 0.35s;
  caret-color: transparent;
  input {
    caret-color: black;
  }
  .loading {
    animation: spin 1.5s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

body{
  margin: 8px 0px;
}
.app.dark {
  background-color: #111;
  color: rgb(220, 220, 220);

  .newLiveData {
    .optList {
      table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        max-width: 100%;
        white-space: nowrap;
      }

      td,
      th {
        border: 1px solid #9e9e9e;
        text-align: left;
        padding: 8px;
      }

      tr:nth-child(even) {
        background-color: #272727;
      }
    }
  }

  input {
    background-color: #222;
    color: #9e9c9c;
    caret-color: white;
  }
  textarea {
    background-color: #222;
    color: rgb(220, 220, 220);
    caret-color: white;
  }
  .sidebar {
    background-color: #111;

    border-right: 0.5px solid rgb(90, 90, 90);
    .icon {
      color: #cdcdcd;
    }
    .title {
      color: #898989;
    }
    .top {
      border-bottom: 0.5px solid rgb(90, 90, 90);
      span {
        color: #cdcdcd;
      }
    }
    span {
      color: #898989;
    }
    li {
      &:hover {
        background-color: rgba(65, 65, 65, 0.349);
      }
    }
  }
  .navbar {
    color: #898989;
    border-bottom: 0.5px solid rgb(90, 90, 90);
    .search {
      caret-color: white;
    }
  }
  .chart {
    .menu {
      .middle {
        text-shadow: 0px 0px 10px rgba(66, 66, 66, 0.43);
      }
      .right {
        .ativo {
          color: rgb(40, 150, 40);
        }
        .inativo {
          color: rgb(200, 40, 40);
        }
      }
    }
  }
  .usersList {
    td,
    th {
      border: 1px solid #9e9e9e;
    }
    thead,
    th {
      background-color: #111;
      span,
      svg {
        color: rgb(220, 220, 220) !important;
      }
    }
    i {
      color: rgb(216, 213, 213);
    }
    .typeRow {
      .typeIcon {
        font-weight: 400;
      }
    }
    .MuiTable-root {
      background-color: #111;
    }
    .MaterialTableToolbar {
      display: flex;
      height: 35px;
      align-items: center;
      background-color: #161616;
      border-radius: 0px;
      .toolItem {
        &:hover {
          background-color: #333333;
        }
      }
      .janelaFiltro {
        .filterOption {
          background-color: rgb(32, 32, 32);
        }
        .checked {
          background-color: rgb(15, 15, 15);
        }
      }
    }
    .MuiTableRow-head {
      background-color: #111;
      color: rgb(220, 220, 220);
    }
    .MuiTableRow-root {
      color: rgb(220, 220, 220);
    }
    .MuiTableRow-root:nth-child(odd) {
      background-color: #272727;
    }
    .MuiTableRow-root:nth-child(even) {
      background-color: #111;
    }
    .MuiTableRow-footer,
    span,
    svg,
    div {
      color: rgb(220, 220, 220);
    }
  }
}
