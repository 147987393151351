.home{
    display: flex;

    .homeCont{
        flex: 6;

        .sidebar-Aguardando {
            flex: 1;
            border-right: 0.5px solid rgb(233, 231, 231);
            /*min-width: 270px;
            min-height: 100vh;*/
            background-color: white;
          
            .top {
              height: 35px;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border-bottom: 2px solid #FAD40C;
              background: #6B4380; 
              color: white;
            }
          }          
        
        .content{
            display: flex;
            justify-content: center; //cetraliza verticalment
            margin: 10px;

            .botaoConfirma{
                opacity: 0.5; /* Altera para 50% de opacidade ao passar o mouse */
            }

            .loader{
                top: 50%;
                left: 0;
                width: 100%;
                height: 70px;
                justify-content: center;
                display: flex;
                position: fixed;
            }
            .widgets{
                display: flex;
                padding: 20px;
                gap: 20px;
                flex: 1;
            }

            .dinheiro{
                color: #39ff32;
                font-size: 50px;
            }
        }
    }
}