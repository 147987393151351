.numeric-keyboard {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .display {
    font-size: 2rem;
    text-align: right;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .keys {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .button-teclado {
    font-size: 1.5rem;
    padding: 15px;
    border: none;
    border-radius: 5px;
    background-color: #50a1f1;
    cursor: pointer;
    transition: background-color 0.3s;
    color: white;
  }
  
  .button-teclado:hover {
    background-color: #2e669e;
  }
  